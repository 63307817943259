import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";
import HTMLContent from "../components/Content";
import "../styles/training.scss";

export const TrainingPageTemplate = props => {
  const { page } = props;

  const { address, trainingTimes, introduction } = page.frontmatter;

  return (
    <article className="training">
      <div className="training-container  container">
        <section className="training-header">
          <div className="training-titleWrapper">
            <h1 className="training-title">{page.frontmatter.title}</h1>
          </div>
        </section>

        <section>
          <ReactMarkdown className="training-intro" source={introduction} />
        </section>

        <section className="address">
          <h2>Wo trainieren wir?</h2>
          <p>
            {address.firstLine}
            <br />
            {address.secondLine}
          </p>
          <p>
            {address.street}
            <br />
            {address.postalCode} {address.city}
          </p>
        </section>

        <section className="trainingTimes">
          <h2>Wann trainieren wir?</h2>
          <table>
            <thead>
              <tr>
                <th>Wochentag:</th>
                <th>Uhrzeit:</th>
                <th>Lehrer:</th>
              </tr>
            </thead>
            <tbody>
              {trainingTimes.map((t, i) => (
                <tr key={i}>
                  <td>{t.day}</td>
                  <td>{t.time}</td>
                  <td>{t.teacher}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section>
          {/* The page.html is actually markdown when viewing the page from the netlify CMS,
              so we must use the ReactMarkdown component to parse the mardown in that case  */}
          {page.bodyIsMarkdown ? (
            <ReactMarkdown className="training-body" source={page.html} />
          ) : (
            <HTMLContent className="training-body" content={page.html} />
          )}
        </section>
      </div>
    </article>
  );
};

const TrainingPage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle }
    }
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <TrainingPageTemplate page={{ ...page, bodyIsMarkdown: false }} />
    </Layout>
  );
};

TrainingPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default TrainingPage;

export const trainingPageQuery = graphql`
  query TrainingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seo {
          browserTitle
          title
          description
        }
        introduction
        address {
          firstLine
          secondLine
          street
          postalCode
          city
        }
        trainingTimes {
          day
          time
          teacher
        }
      }
    }
    ...LayoutFragment
  }
`;
