import React from "react";
import "./styles.scss";

export const FooterTemplate = () => {
  return (
    <footer className="footer">
       copyright © {new Date().getFullYear()} - magokoro aikido dojo
    </footer>
  );
};

export const Footer = () => {
  return <FooterTemplate />;
};
