import React from "react";
import { Link } from "gatsby";

import "./styles.scss";
import CustomLink from "../CustomLink";

export class NavbarTemplate extends React.Component {
  state = { mobileMenuOpened: false };

  toggleMobileMenu = () =>
    this.setState(state => ({
      mobileMenuOpened: !state.mobileMenuOpened
    }));

  render() {
    const { data } = this.props;
    return (
      <nav className="navbar">
        <div className="container  navbar-container">
          <Link className="logo" to="/">
            <img src="/media/logo-text.svg" alt="magokoro aikido dojo" />
          </Link>
          {data.menuItems.length > 0 && (
            <>
              <div className="navbar-desktop-wrapper">
                <ul className="navbar-menu">
                  {data.menuItems.map(menuItem => (
                    <li key={menuItem.linkURL} className="menuItem">
                      <CustomLink
                        linkType={menuItem.linkType}
                        linkURL={menuItem.linkURL}
                        className={`menuLink${
                          menuItem.label === "training" ? " cta" : ""
                        }`}
                      >
                        {menuItem.label}
                      </CustomLink>
                    </li>
                  ))}
                </ul>
                <Link className="logo" to="/">
                  <img
                    src="/media/logo-display.svg"
                    alt="magokoro grafik"
                    className="display-logo"
                  />
                </Link>
              </div>
              <div className="navbar-mobile-wrapper">
                <button
                  className="open-menu-button"
                  onClick={this.toggleMobileMenu}
                >
                  <img src="/media/hamburger.svg" alt="menü öffnen" />
                </button>
                {this.state.mobileMenuOpened && (
                  <nav className="navbar-mobile-menu">
                    <h3>Menü</h3>
                    <button
                      className="close-button"
                      onClick={this.toggleMobileMenu}
                    >
                      &times;
                    </button>
                    <ul>
                      {data.menuItems.map(menuItem => (
                        <li key={menuItem.linkURL} className="menuItem">
                          <CustomLink
                            linkType={menuItem.linkType}
                            linkURL={menuItem.linkURL}
                            className="menuLink"
                          >
                            {menuItem.label}
                          </CustomLink>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}
              </div>
            </>
          )}
        </div>
      </nav>
    );
  }
}

const Navbar = props => {
  if (!props.data) {
    return null;
  }
  const data = props.data.edges[0].node.frontmatter;
  return <NavbarTemplate data={data} />;
};

export { Navbar };
